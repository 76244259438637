<template>
  <ModuleContainer>
    <div class="layer-container pa-2">
      <ButtonGetLocation @update:location="$emit('update:location', $event)" />
      <v-btn
        dark
        small
        v-for="(layer, id) in layersMap"
        :key="id"
        rounded
        :ripple="false"
        @click="onToggleShowLayer(layer)"
        class="text-capitalize d-inline-block mx-1 mb-2"
        :color="layer.show ? '#428CD4' : ''"
      >
        <v-icon left small> mdi-map-marker-outline </v-icon>
        {{ layer.name }}
        <template v-if="isMobile"> trong khu vực </template> ({{
          countFeatureLayer[layer.placeTypeId] ||
          layer.features_count | formatNumber
        }})
      </v-btn>
    </div>
  </ModuleContainer>
</template>

<script>
import { cache, loadImage, loadJson } from "../../../control/helper";
import { bbox as bboxTurf } from "@turf/turf";

import { promiseMapboxLayerManagement } from "@/worker";
import ButtonGetLocation from "@components/ButtonGetLocation.vue";
import ModuleMixin from "../../mixins/ModuleMixin";
import { mapGetters } from "vuex";
import SearchResultPopupVue from "@/views/SearchResultPopup.vue";
import Vue from "vue";
import L from "leaflet";
import "leaflet.markercluster";
import "leaflet.markercluster/dist/MarkerCluster.Default.css";
const LAYER_CACHE = {};
const ICON_LAYER_CACHE = {},
  LAYER_SEARCH_LAYER_CACHE = {};
export default {
  components: { ButtonGetLocation },
  mixins: [ModuleMixin],
  props: { isComplexSearch: Boolean },
  data: () => ({ layersMap: {}, countFeatureLayer: {} }),
  watch: {
    items: {
      handler(value) {
        this.onSetData();
      },
    },
  },
  computed: {
    ...mapGetters({
      layers: "map/layers",
      itemsLayer: "map/itemsLayer",
      items: "map/items",
    }),
    isMobile() {
      return this.$vuetify.breakpoint.mobile;
    },
    countLayers() {
      return Object.keys(this.layersMap).length;
    },
  },
  methods: {
    onInit() {
      this.bindedOnClickLayer = this.onClickLayer.bind(this);
      this.bindedOnMouseEnterLayer = this.onMouseEnterLayer.bind(this);
      this.bindedOnMouseLeaveLayer = this.onMouseLeaveLayer.bind(this);
      let spriteUrl = "https://tiles.mattech.vn/styles/basic/sprite";
      let promise;
      if (spriteUrl) {
        const fetchObj = transformRequest(spriteUrl);
        const existing = cache.fetch(fetchObj.url);
        if (existing) {
          existing.then(([image, json]) => {
            this.setSprite({
              image,
              json,
            });
          });
        } else {
          promise = Promise.all([
            loadImage(spriteUrl + ".png", { transformRequest }),
            loadJson(spriteUrl + ".json", { transformRequest }),
          ]);
          cache.add(spriteUrl, promise);
          promise.then(([image, json]) => {
            this.setSprite({
              image,
              json,
            });
          });

          return () => {
            cache.release(spriteUrl);
          };
        }
      }
    },
    onDestroy() {
      Object.keys(LAYER_CACHE).forEach((key) => {
        let layer = LAYER_CACHE[key];
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
      Object.keys(LAYER_SEARCH_LAYER_CACHE).forEach((key) => {
        let layer = LAYER_SEARCH_LAYER_CACHE[key];
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
      });
    },
    setSprite(sprite) {
      this.sprite = sprite;
      this.onSetData();
    },

    onSetData() {
      if (!this.map || !this.layers || this.layers.length < 1) return;
      Object.keys(this.layers).forEach((key) => {
        let layer = this.layers[key];
        var markers = L.markerClusterGroup();
        let style = layer.style;
        let icon = createIconFromStyle(style, this.sprite);
        let places = this.itemsLayer(key);
        let markers_search = new L.featureGroup([], { style: {} });
        places.forEach((place) => {
          let marker = L.marker(L.latLng(place.lat, place.lng), {
            icon: L.icon({
              iconUrl: icon,
              iconSize: [38, 38],
              iconAnchor: [19, 19],
              shadowSize: [38, 38],
            }),
            zIndexOffset: 200,
          });
          marker.feature = place;
          markers.addLayer(marker);

          markers.on("click", this.bindedOnClickLayer);
          markers.on("mouseover", this.bindedOnMouseEnterLayer);
          markers.on("mouseout", this.bindedOnMouseLeaveLayer);
        });
        this.map.addLayer(markers);
        layer.show = true;
        LAYER_CACHE[layer.id] = markers;
        ICON_LAYER_CACHE[layer.id] = icon;
        this.$set(this.layersMap, layer.id, layer);
      });
    },
    onToggleShowLayer(layer) {
      layer.show = !layer.show;
      this.$set(this.layersMap, layer.id, layer);
      this.$forceUpdate();
      let layerMap = LAYER_CACHE[layer.id];
      let layer_search = LAYER_SEARCH_LAYER_CACHE[layer.id];
      if (layer_search) {
        layerMap = layer_search;
      }
      if (layer.show) {
        this.map.addLayer(layerMap);
      } else {
        this.map.removeLayer(layerMap);
      }
    },
    onClickLayer(e) {
      let data = e.layer.feature;
      if (data) {
        this.$emit("click:layer", { properties: { data: data } });
      }
    },
    onMouseLeaveLayer() {
      if (this.layerVuePopup) this.layerVuePopup.check();
    },
    onMouseEnterLayer(e) {
      if (this.layerPopup) {
        this.layerPopup.remove();
      }
      if (this.isMobile) return;
      let data = e.layer.feature;

      let coordinates = L.latLng(data.lat, data.lng);
      this.layerVuePopup = new Vue(SearchResultPopupVue);
      this.layerVuePopup.$mount();
      this.layerVuePopup.setData(data);
      this.layerVuePopup.$on("close", () => {
        if (this.layerPopup) {
          this.layerPopup.remove();
        }
        if (this.layerVuePopup) {
          this.layerVuePopup.$destroy();
          this.layerVuePopup = null;
        }
      });
      this.layerVuePopup.$on("click:item", (e) => {
        this.$emit("click:layer", { properties: { data: e } });
      });
      // Populate the popup and set its coordinates
      // based on the feature found.
      this.layerPopup = L.popup({})
        .setLatLng(coordinates)
        .setContent(this.layerVuePopup.$el)
        .openOn(this.map);
    },
    onRemovePopup() {
      if (this.popup) {
        this.popup.remove();
      }
    },
    onShowPopup(coordinates, html = "<div></div>") {
      this.onRemovePopup();
      coordinates = L.latLng(coordinates[1], coordinates[0]);
      this.popup = L.popup({})
        .setLatLng(coordinates)
        .setContent(html)
        .openOn(this.map);
    },
    flyTo(center, zoom = 12, padding = []) {
      if (!this.map) return;
      center = L.latLng(center[1], center[0]);
      this.map.setView(center, zoom, { animation: false });
    },
    onMarket(coordinates) {
      if (!this.map) return;
      this.onRemoveMarket();
      let marker = createHightlightMarker(coordinates);
      this.circleLayers = new L.LayerGroup([marker]);
      this.circleLayers.setZIndex(30).addTo(this.map);
    },
    onRemoveMarket() {
      if (!this.circleLayers) return;
      this.circleLayers.clearLayers();
      this.circleLayers.remove();
      this.circleLayers = null;
    },
    onMarketItems(arrayCoordinates) {
      this.onRemoveMarket();
      let markers = arrayCoordinates.map((coordinates) =>
        createHightlightMarker(coordinates)
      );
      this.circleLayers = new L.LayerGroup(markers);
      this.circleLayers.setZIndex(30).addTo(this.map);
    },
    resize() {
      if (this.map) this.map.invalidateSize();
    },
    async onSetDataSearch(
      places,
      { fitBounds, padding, bbox } = {},
      { count = {} } = {}
    ) {
      let features = [];
      Object.keys(LAYER_CACHE).forEach((key) => {
        let layer = LAYER_CACHE[key];
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
        if (!LAYER_SEARCH_LAYER_CACHE[key]) {
          let layer_search = new L.FeatureGroup([], {});
          LAYER_SEARCH_LAYER_CACHE[key] = layer_search;
          if (this.layersMap[key].show) this.map.addLayer(layer_search);
        } else {
          LAYER_SEARCH_LAYER_CACHE[key].clearLayers();
        }
      });
      places.forEach((place) => {
        let layer = place.place;
        let markers = LAYER_SEARCH_LAYER_CACHE[layer.id];
        let icon = ICON_LAYER_CACHE[layer.id];
        let marker = L.marker(L.latLng(place.lat, place.lng), {
          icon: L.icon({
            iconUrl: icon,
            iconSize: [38, 38],
            iconAnchor: [19, 19],
            shadowSize: [38, 38],
          }),
          zIndexOffset: 300,
        });
        marker.feature = place;
        markers.addLayer(marker);
      });

      this.countFeatureLayer = count;
      if (places && places.length > 0) {
        let res = await promiseMapboxLayerManagement.postMessage({
          items: places,
        });
        features = res.features;
      }
      if (fitBounds) {
        if (features.length > 0) {
          bbox = bboxTurf({
            type: "FeatureCollection",
            features,
          });
        }
        if (bbox) {
          let [west, south, east, north] = bbox;
          this.map.fitBounds(
            [
              [south, west],
              [north, east],
            ],
            {
              paddingTopLeft: [450, 10],
              duration: 0,
            }
          );
        }
      }
    },
    onShowData() {
      Object.keys(LAYER_SEARCH_LAYER_CACHE).forEach((key) => {
        let layer = LAYER_SEARCH_LAYER_CACHE[key];
        if (this.map.hasLayer(layer)) {
          this.map.removeLayer(layer);
        }
        LAYER_SEARCH_LAYER_CACHE[key] = null;
      });
      Object.keys(LAYER_CACHE).forEach((key) => {
        let layer = LAYER_CACHE[key];
        if (!this.map.hasLayer(layer)) {
          this.map.addLayer(layer);
        }
      });
    },
  },
};
function createHightlightMarker(coordinates) {
  let marker = new L.CircleMarker(L.latLng(coordinates[1], coordinates[0]), {
    color: "#789EFD",
    radius: 20,
    stroke: true,
    width: 2,
    fill: true,
    fillColor: "#2E63E9",
    fillOpacity: 0.3,
  });
  return marker;
}
const transformRequest = (url) => {
  return {
    url: url,
  };
};

function extractPartOfImage(img, { x, y, width, height }) {
  const dpi = 1;
  const el = document.createElement("canvas");
  el.width = width * dpi;
  el.height = height * dpi;
  const ctx = el.getContext("2d");
  ctx.drawImage(
    img,
    x * dpi,
    y * dpi,
    width * dpi,
    height * dpi,
    0,
    0,
    width * dpi,
    height * dpi
  );
  return el.toDataURL();
}
function createIconFromStyle(style, sprite) {
  if (style.type == "symbol") {
    return extractPartOfImage(
      sprite.image,
      sprite.json[style.layout["icon-image"]]
    );
  }
}
</script>
<style>
.leaflet-popup-content {
  margin: 0 !important;
}
</style>
