<template>
  <v-btn
    color="#EA4492"
    small
    rounded
    dark
    depressed
    class="text-capitalize d-inline-block mx-1 mb-2"
    @click="onGetLocation()"
    :readonly="disabledGetlocation"
  >
    <v-icon left small>{{
      disabledGetlocation ? "mdi-crosshairs-off" : "mdi-crosshairs-gps"
    }}</v-icon>
    {{ disabledGetlocation ? "Không thể lấy vị trí" : "Địa điểm gần nhất" }}
  </v-btn>
</template>

<script>
import { getLocation } from "@/utils";
export default {
  data: () => ({ disabledGetlocation: false }),
  methods: {
    onGetLocation() {
      getLocation()
        .then((location) => {
          if (!location) {
            this.disabledGetlocation = true;
            return;
          }
          this.location = {
            lat: location.latitude,
            lng: location.longitude,
          };
          this.$emit("update:location", this.location);
        })
        .catch((e) => {
          this.disabledGetlocation = true;
          console.error(e);
        });
    },
  },
};
</script>

<style></style>
